import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ImageSlider from './imageslider';

const LoadingComponent = ({id}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Generate a random number between 20 to 30 seconds
    const randomSeconds = Math.floor(Math.random() * 11) + 20;
    const targetTime = randomSeconds * 1000; // Convert to milliseconds

    // Calculate the interval time based on the target time to reach 90%
    const intervalTime = (targetTime * 90) / 100 / 90;

    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        // If the progress is 90 or more, stop the interval
        if (oldProgress >= 90) {
          clearInterval(interval);
          return 90;
        }
        // Increment the progress
        return Math.min(oldProgress + 1, 90);
      });
    }, intervalTime);

    return () => {
      clearInterval(interval);
    };
  }, []);


  function getRandomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const texts = ["Quarantining suspicious files",
    "Analyzing crucial clues",
    "Contemplating complex evidence",
    "Tracing elusive data trails",
    "Engaging with advanced AI for insights",
    "Navigating through cyber labyrinths",
    "Compiling critical evidence",
    "Brewing a detective's strong coffee",
    "Refining search algorithms",
    "Directing digital detectives",
    "Consulting the enigmatic Web Oracle",
    "Sifting through cyber archives",
    "Reviving dormant data sources",
    "Harmonizing chaotic information flows",
    "Fine-tuning intuition sensors",
    "Activating stealth mode",
    "Unearthing hidden digital secrets",
    "Instructing AI in the art of deduction",
    "Assembling key data points",
    "Reconfiguring virtual environments",
    "Cultivating information networks",
    "Piecing together the final puzzle",
    "Awaiting the convergence of digital destinies",
    "Interpreting esoteric code languages"]
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex) => (currentIndex + 1) % texts.length);
    }, getRandomNumberInRange(1500,2000)); // Change text every 2000 milliseconds (2 seconds)

    return () => clearInterval(interval);
  }, [texts.length]);

  // Define your inline styles here
  const containerStyle = {
    fontSize: '12px',
    display: 'flex',      // Enable flexbox
    alignItems: 'center', // Vertically center align the items
           // Optional: add some space between text and progress bar
  };

  return (
    <div style={containerStyle}>

       <div  style={{ transform: 'scale(0.3)' }}>
        <img
          src={process.env.PUBLIC_URL + 'detect.gif'}
          alt="Loading..." 
          width = '70px'
          height = '70px'
        />
        {/* <CircularProgress variant="determinate" style={{ color: '#7407FF' }} value={progress} id={id}/> */}
        {/* <CircularProgress style={{ color: '#7407FF' }}/> */}
      </div>
      <p>{texts[currentIndex]}</p>
    </div>
  );
};

export default LoadingComponent;
