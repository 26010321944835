import React from 'react';
import './Loader.css'; // Assuming you have the CSS in a file named Loader.css

const Loader = () => {
  return (
    
  <div class="loader">
  <span class="loader-text">extracting facts...</span>
    <span class="load"></span>
</div>

  );
};

export default Loader;
