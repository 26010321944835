import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Dialog, DialogContent, Typography, Box } from "@mui/material";
import { purple } from "@mui/material/colors";
import GoogleIcon from '@mui/icons-material/Google';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TermsAndConditionsDialog from "./TermsandConditions";
function useQuery() {
  return new URLSearchParams(window.location.search);
}

function DividerWithText() {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }}>
      <hr style={{
        width: '40%', 
        marginRight: '10px'
      }} />
      <span style={{
        whiteSpace: 'nowrap'
      }}>
        or
      </span>
      <hr style={{
        width: '40%', 
        marginLeft: '10px'
      }} />
    </div>
  );
}


const LoginModal = ({ open, notify, setDisplayedPage }) => {
  const { loginWithRedirect } = useAuth0();
  const [agreed, setAgreed] = useState(false)
  const query = useQuery();

  useEffect(() => {
    const error = query.get('error');
    const errorDescription = query.get('error_description');

    if (error === "access_denied") {
      notify(decodeURIComponent(errorDescription));
      console.log(decodeURIComponent(errorDescription));
    }
  }, [query, notify]);

  const handleLogin = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <Dialog open={open} onClose={() => {}} PaperProps={{ style: { minHeight: '600px', minWidth: '400px' } }}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" style={{ minHeight: '600px' }}>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', fontFamily: 'Inter, sans-serif' }}>
          <Typography variant="h1" style={{ color: '#0066FF', fontSize: '50px', fontWeight: '500' }}>
            FACTICITY
          </Typography>
          <Typography variant="subtitle1" style={{ color: '#5D5D5D', fontSize: '20px' , fontWeight: '400', lineHeight: '1.2' }}>
            Check Untruths Before They<br />  Become Powerful
          </Typography>
        </div>


          {/* <img 
            src="https://storage.googleapis.com/public_resources_seer/seer.png" 
            alt="Facticity.AI Logo" 
            style={{ marginBottom: '40px', width: '150px', height: '37px' }}
          /> */}
          <br></br>
          <div style={{
            backgroundColor: '#FFFFFF',
            padding: '20px',
            fontFamily: 'Inter, sans-serif',  // Make sure 'Inter' font is available
            textAlign: 'left'  // This will align the text to the left
          }}>
            <p style={{
              fontSize: '40px',
              fontWeight: '500',
              margin: '0'  // Remove default margin for <p>
            }}>
              Log In &
            </p>              
            <p style={{
              fontSize: '25px',
              fontWeight: '500',
              color: '#5D5D5D',
              margin: '0'  // Remove default margin for <p>
            }}>
              Let's Get Fact-Checking
            </p>  
            <br></br>
            <Button 
            onClick={handleLogin} 
            style={{ 
              color: 'white', 
              backgroundColor: 'black', 
              marginBottom: '20px', 
              width: '100%' 
            }}
            startIcon={<GoogleIcon />}
          >
            Sign In with Google
          </Button>
          <Button 
            onClick={handleLogin} 
            style={{ 
              color: 'white', 
              backgroundColor: 'black', 
              marginBottom: '20px', 
              width: '100%' 
            }}
            startIcon={<MailOutlineIcon/>}
          >
            Sign In with  email
          </Button>
          <DividerWithText/>
          <br></br>
          <Button
            // variant="outlined"
            style={{
              backgroundColor: '#D1D1D1',
              color: 'D1D1D1',
              boxShadow: 'none',
              width: '100%',
              marginBottom: '20px'
            }}
            onClick={() => { setDisplayedPage('signup') }}
          >
            Sign Up
          </Button>
          <div style={{ color: '#636366' }}>  {/* Choose your desired color */}
            <br />
            <TermsAndConditionsDialog agreed={agreed} setAgreed={setAgreed} mode="simple" message="By continuing you agree with Facticity's " />
            <br />
          </div>

          </div>

          </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoginModal;
