import React, { useState, useEffect } from 'react';

const ImageSlider = () => {
  const [position, setPosition] = useState(0); // 0 for left, 1 for right

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(prevPosition => (prevPosition === 0 ? 1 : 0));
    }, 2000); // Change direction every 2 seconds

    return () => clearInterval(interval);
  }, []);

  const loadingImageStyle = {
    position: 'absolute',
    width: '50px', // Adjust as needed
    transform: position === 1 ? 'translateX(100%) scaleX(-1)' : 'translateX(0) scaleX(1)',
    transition: 'transform 2s ease-in-out',
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '200px', overflow: 'hidden' }}>
      <img 
        src={process.env.PUBLIC_URL + 'detective.png'}
        alt="Loading..." 
        style={loadingImageStyle} 
      />
    </div>
  );
};

export default ImageSlider;


// import React, { useState, useEffect } from 'react';

// const ImageSlider = () => {
//   const [position, setPosition] = useState(-100);

//   useEffect(() => {
//     const moveImage = () => {
//       const newPosition = position + 1;
//       setPosition(newPosition);

//       if (newPosition > 100) {
//         clearInterval(intervalId);
//       }
//     };

//     const intervalId = setInterval(moveImage, 10);

//     return () => clearInterval(intervalId);
//   }, [position]);

//   const imageStyle = {
//     position: 'absolute',
//     width: '100%',
//     left: `${position}%`,
//     transition: 'left 0.1s ease-out',
//   };

//   return (
//     <div style={{ position: 'relative', width: '100%', height: '20px', overflow: 'hidden' }}>
//       <img
//         src={process.env.PUBLIC_URL + 'detective.png'}
//         alt="Moving Image"
//         style={imageStyle}
//       />
//     </div>
//   );
// };

// export default ImageSlider;
