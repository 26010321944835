import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Alert, IconButton, InputAdornment } from '@mui/material';
import { purple } from "@mui/material/colors";
import TermsAndConditionsDialog from './TermsandConditions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const SignupForm = ({ setDisplayedPage }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    company: '',
    occupation: '',
    country: '',
    industry: '',
    linkedin: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [agreed, setAgreed] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const checkUserExists = async (email) => {
    const url = `https://facticity-backend-a4xncz5jja-as.a.run.app/get-user/${email}`;
    const response = await fetch(url);
    const data = await response.json();
    console.log(data);
    return data.length > 0;
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])(?=.{8,})/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    const { email, password, confirmPassword, firstName, lastName, company, occupation, country, industry, linkedin } = formData;

    if (!agreed || !email || !password || !confirmPassword || !firstName || !lastName || !company || !occupation) {
      setError('All required fields must be filled.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.');
      return;
    }

    const data = {
      client_id: 'fvsQ9DT16eWAbmF6IL6ZApvkpdgjtf3L',
      email,
      password,
      connection: 'Username-Password-Authentication',
      user_metadata: {
        first_name: firstName,
        last_name: lastName,
        company,
        occupation,
        country,
        industry,
        linkedin,
        agreed: String(agreed)
      }
    };

    console.log(data);

    try {
      const userExists = await checkUserExists(data.email);

      if (userExists) {
        setError('You have already signed up. Please log in.');
        return;
      }

      const response = await fetch('https://dev-5h33aun.us.auth0.com/dbconnections/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const result = await response.json();
      console.log({result});

      if (result.code === 'invalid_signup') {
        setError(result.description);
      } else {
        if (result.error) {
          setError(`Error: ${result.error_description || result.error}`);
        } else {
          setSuccess('Signup successful!');
          window.location.href = 'https://platform.facticity.ai/#/email-verification';
        }
      }
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ArrowBackIcon onClick={() => { setDisplayedPage('home') }} />
        <Typography component="h1" variant="h5">
          Welcome to Facticity, Sign Up
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formData.email}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="First Name"
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Last Name"
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="company"
            label="Company"
            type="text"
            id="company"
            value={formData.company}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="occupation"
            label="Occupation"
            type="text"
            id="occupation"
            value={formData.occupation}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="country"
            label="Country"
            type="text"
            id="country"
            value={formData.country}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="industry"
            label="Industry"
            type="text"
            id="industry"
            value={formData.industry}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="linkedin"
            label="LinkedIn Profile"
            type="url"
            id="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
          />
          <TermsAndConditionsDialog agreed={agreed} setAgreed={setAgreed} />
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            style={{
              backgroundColor: purple[200], // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default SignupForm;
