import React from 'react';

class Permissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      havePermissions: false,
    };
    this.checkPermissions = this.checkPermissions.bind(this);
  }

  checkPermissions() {
    const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
    
    permissions.then((stream) => {
      alert('Accepted the permissions');
      this.setState(prevState => ({
        havePermissions: true
      }));
    })
    .catch((err) => {
      this.setState(prevState => ({
        havePermissions: false
      }));
      console.error(`${err.name} : ${err.message}`);
    });
  }
  
  render() {
    return (
      <button onClick={this.checkPermissions}>
        Permissions button {this.state.havePermissions ? 'Granted' : 'Denied'}
      </button>
    );
  }
}

export default Permissions;
