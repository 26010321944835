import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const VideoProcessingLoader = ({ videoDuration, done, claim }) => {
  const [progress, setProgress] = useState(0);
  const [estimatedProcessingTime, setEstimatedTime] = useState(videoDuration * 0.125)
  const [timeLeft, setTimeLeft] = useState(estimatedProcessingTime);
  const [claim_processing, setClaimProcessing] = useState(claim)

  useEffect(()=>{
    setProgress(0)
    setTimeLeft( videoDuration * 0.125);
    console.log({estimatedProcessingTime})
  },[claim_processing])




  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 75) {
          return oldProgress;
        }
        const diff = 75 / estimatedProcessingTime;
        return Math.min(oldProgress + diff, 75);
      });
      setTimeLeft((oldTime) => {
        if (oldTime === 0) {
          clearInterval(interval);
          return 0;
        }
        return oldTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [estimatedProcessingTime, claim_processing]);

  useEffect(() => {
    if (done) {
      setProgress(100);
      setTimeLeft(0);
    }
  }, [done, claim_processing]);

  return (
    <Box sx={{ width: '100%' }}>
        <Typography variant="body2" color="textSecondary">
            {`Extracting claims:`}
        </Typography>
        <LinearProgress 
        variant="determinate" 
        value={progress} 
        sx={{
            backgroundColor: 'white', 
            '& .MuiLinearProgress-bar': {
            backgroundColor: 'black',
            },
        }} 
        />

      <Box display="flex" justifyContent="space-between" mt={2}>
        {/* <Typography variant="body2" color="textSecondary">
          {`Estimated Time Left: ${timeLeft}s`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(progress)}%`}
        </Typography> */}
      </Box>
    </Box>
  );
};

export default VideoProcessingLoader;
