import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

const ThumbsUpDownButtons = (id, handleThumbsResponse) => {
  const [thumbsUpCount, setThumbsUpCount] = useState(0);
  const [thumbsDownCount, setThumbsDownCount] = useState(0);
  const [thumbsUpClicked, setThumbsUpClicked] = useState(false);
  const [thumbsDownClicked, setThumbsDownClicked] = useState(false);

  const handleThumbsUpClick = () => {
    setThumbsUpClicked(true);
    setThumbsDownClicked(false);
    setThumbsUpCount(thumbsUpCount + 1);
    handleThumbsResponse(id,"positive");
  };

  const handleThumbsDownClick = () => {
    console.log(id)
    setThumbsDownClicked(true);
    setThumbsUpClicked(false);
    setThumbsDownCount(thumbsDownCount + 1);
    handleThumbsResponse(id,"negative");
  };

  async function handleThumbsResponse(id, feedback){
    try {
      // const apiUrl = 'http://127.0.0.1:5000/SendThumbsResponse';
      const apiUrl = 'https://facticity-backend-a4xncz5jja-as.a.run.app/SendThumbsResponse';
      const requestBody = { id: id, response: feedback}; 

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      return data
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div>
      <IconButton onClick={handleThumbsUpClick} >
        <ThumbUpOffAltIcon style={{ color: thumbsUpClicked ? '#7407FF' : '#808080' }} />
      </IconButton>
      <IconButton onClick={handleThumbsDownClick} >
        <ThumbDownOffAltIcon style={{ color: thumbsDownClicked ? '#7407FF' : '#808080' }}/>
      </IconButton>
    </div>
  );
};

export default ThumbsUpDownButtons;
