import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import { purple } from "@mui/material/colors";


function useQuery() {
  return new URLSearchParams(window.location.search);
}

const LoginButton = ({notify, setDisplayedPage}) => {
  const { loginWithRedirect } = useAuth0();
  const query = useQuery();

  useEffect(() => {
    const error = query.get('error');
    const errorDescription = query.get('error_description');

    if (error === "access_denied") {
      notify(decodeURIComponent(errorDescription))
      console.log(decodeURIComponent(errorDescription));
    }
  }, []);

  const handleLogin = async () => {
    try {
      var a = await loginWithRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  

  return (
  <div>
    {/* <Button onClick={handleLogin} style={{ color: 'white' }}>Sign In with Google</Button> */}
    {/* <Button onClick={handleLogin} style={{ color: 'white' }}>Sign In</Button>
    <Button variant="outlined" 
                style={{
                  backgroundColor: 'white', // Darker purple button
                  color: purple[500], // White text
                  boxShadow: 'none' // Ensure button is flat
                }}
                onClick={()=>{setDisplayedPage('signup')}}>Sign up</Button> */}
  </div>
  
);
};

export default LoginButton;
