import React from 'react';
import { Card, CardContent, Typography, Grid, Box, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';
import { green, grey, purple } from '@mui/material/colors';


const PricingContainer = styled(Card)(({ theme }) => ({
  height: '100%',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));


const features = [
    "Unlimited daily text based fact checks",
    "Unlimited article checks",
    "video fact checks per month",
    "Source citations",
    "133 languages supported (text based)",
    "browser extension (coming soon)",
    "Fact check yourself as you type (coming soon)",
    "Live video fact checking (coming soon)",
    "Advanced features and support for power users, businesses, and organizations"
  ];

const hours = {BetaUser:'3 hours of ', Basic: '1 hour of ', Premium: '3 hours of ', Enterprise: '9 hours of '}

const planFeatures = {
BetaUser: [true, true, true, true, true, true, false, false],
Basic: [true, true, true, true, true, true, true, false],
Premium: [true, true, true, true, true, true, true, false],
Enterprise: [true, true, true, true, true, true, true, true, true]
};

const PricingCard = ({ title, plan }) => (
    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'auto' }}>
      <Typography variant="h5" color={purple[500]}>{title}</Typography>
      <Typography variant="body2" color="text.secondary">
        {title === 'Beta user' && 'Free for a limited time'}
        {title === 'Basic' && '$9 USD per month'}
        {title === 'Premium' && '$25 USD per month'}
        {title === 'Enterprise' && 'Contact us for more info'}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        <ul style={{ paddingLeft: '20px', margin: 0 }}>
        {features.map((feature, index) => (
            index === 2 ? (
                <li key={index} style={{ color: planFeatures[plan][index] ? green[500] : grey[500] }}>
                {hours[plan]} {feature}
                </li>
            ) : (
                <li key={index} style={{ color: planFeatures[plan][index] ? green[500] : grey[500] }}>
                {feature}
                </li>
            )
            ))}

        </ul>
      </Typography>
    </CardContent>
  );

const PricingPage = ({ setDisplayedPage }) => {
  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        
        <Grid container spacing={4} justifyContent="center">
        <ArrowBackIcon onClick={() => { setDisplayedPage('home') }} />
        <Grid item xs={12} md={8}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={3}>
                <PricingContainer>
                    <PricingCard title="Beta user" plan="BetaUser" />
                </PricingContainer>
              


              </Grid>
              <Grid item xs={12} md={3}>
                <PricingContainer>
                    <PricingCard title="Basic" plan="Basic" />
                </PricingContainer>
              

              </Grid>
              <Grid item xs={12} md={3}>
                <PricingContainer>
                    <PricingCard title="Premium" plan="Premium" />
                </PricingContainer>
              

              </Grid>
              <Grid item xs={12} md={3}>
                <PricingContainer>
                    <PricingCard title="Enterprise" plan="Enterprise" />
                </PricingContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </Box>
  );
};

export default PricingPage;

