import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutButton from './LogoutButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { purple } from "@mui/material/colors";
import { Button, Card, CardContent } from "@mui/material";


const AccountProfile = ({setDisplayedPage, selectedModel, setSelectedModel}) => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);


  const open = Boolean(anchorEl);



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    isAuthenticated && (
      <div style={{ position: 'relative' }}>
        <Avatar 
          src={user.picture} 
          alt={user.name}
          style={{ width: '30px', height: '30px', cursor: 'pointer' }}
          onClick={handleClick}
        />
        <Menu
          id="profile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'profile-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            style: {
              backgroundColor: '#0A0A0A', // Match card background
              boxShadow: 'none', // Flat design, no shadow
              color: purple[500], // Use purple text color for items
              borderRadius: 20, // Flat design, no rounded corners
            }
          }}
        >
          <LogoutButton setDisplayedPage ={setDisplayedPage} selectedModel = {selectedModel} setSelectedModel = {setSelectedModel} user = {user}/>

          {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLSfwuYw6Euk1oo4BFb3Gq5cvtVYvfpKW5C-3_ED_IhPD76aWRg/viewform">
                  <Button
                      variant="contained"
                      style={{
                        backgroundColor: purple[200], // Darker purple button
                        color: '#fff', // White text
                        boxShadow: 'none' // Ensure button is flat
                      }}
                  >
                    Feedback
                  </Button>
              </a> */}
        </Menu>
      </div>
    )
  );
};

export default AccountProfile;
