// VideoCarousel.js
import React, { useState, useEffect } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper, Typography, Button } from '@mui/material';

import YouTubeEmbed from './video';
import { TikTokEmbed } from 'react-social-media-embed';
import { purple } from "@mui/material/colors";

const VideoCarousel = ({ videos, setInputText, verify }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const buttonStyle = {
        backgroundColor: '#333333', // Darker purple button
        color: '#fff', // White text
        boxShadow: 'none' // Ensure button is flat
      }

    const containerStyle = {
        position: 'relative',
    };
    const handleButtonClick = () => {
        // Assuming you have a function to set the input text
        setInputText(videos[currentIndex].url)
    };
    

    const handleCarouselChange = (newIndex) => {
        setCurrentIndex(newIndex);
    };

    return (
        <div style={containerStyle}>
            <Carousel 
            autoPlay = {false}
            style = {{
                boxShadow: 'none' // Ensure button is flat
              }}
            
            onChange={handleCarouselChange}>
                {videos.map((video, index) => (
                    <VideoItem key={index} video={video} />
                ))}
            </Carousel>
            <Button style={buttonStyle} onClick={handleButtonClick}>Fact Check This</Button>
        </div>
    );
};


const VideoItem = ({ video }) => {
    const isYouTubeUrl = video.url.includes('youtube.com') || video.url.includes('youtu.be');
    const isTikTokUrl = video.url.includes('tiktok.com');
    return (
        <Paper style={{ padding: 20, textAlign: 'center' }}>
            {isYouTubeUrl ? (
                <YouTubeEmbed videoLink={video.url} height={'20vh'} />
            ) : isTikTokUrl ? (
                <TikTokEmbed url={video.url} height={'40vh'}/>
            ) : (
                <video width="100%" controls>
                    <source src={video.url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </Paper>
    );
};

export default VideoCarousel;
