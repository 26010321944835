import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

function EmailConfirmation() {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#e1bee7',
            color: '#FFFFFF' // optional: change text color for better contrast
        }}>
            <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Just One More Step!
                </Typography>
                <Typography variant="body1">
                    We've just sent a confirmation email your way. Please check your inbox and click the link to get fact checking!
                </Typography>
            </Container>
        </Box>
    );
}

export default EmailConfirmation;
