import React from 'react';
import { Typography, Card, CardContent, Grid } from '@mui/material';

const SimpleClaimComponent = ({ claim }) => {
  return (
    <Card variant="outlined" style={{ marginBottom: '10px', fontFamily: 'IBM Plex Sans' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Claim verification for {claim}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SimpleClaimComponent;
