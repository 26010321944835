import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box, Alert } from '@mui/material';
import { purple } from "@mui/material/colors";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TermsAndConditionsDialog from './TermsandConditions';
const AddMetadataForm = ({setDisplayedPage, email, id, updateData}) => {
  const [formData, setFormData] = useState({
    email: email,
    firstName: '',
    lastName: '',
    company: '',
    occupation: '',
    country: '',
    industry: '',
    linkedin: ''
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [agreed, setAgreed] = useState(null)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    const { email, firstName, lastName, company, occupation, country, industry, linkedin } = formData;
  
    if (!agreed || !email || !firstName || !lastName || !company || !occupation) {
      setError('All required fields must be filled.');
      return;
    }
  
    const data = {
      user_id: encodeURIComponent(id),
      user_metadata: {
        first_name: firstName,
        last_name: lastName,
        company,
        occupation,
        country,
        industry,
        linkedin,
        agreed: String(agreed)
      }
    };
  
    try {
      const response = await fetch('https://facticity-backend-a4xncz5jja-as.a.run.app/update_user_metadata', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });
  
      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Failed to update metadata');
      }
  
      setSuccess('Metadata updated successfully!');
      updateData();
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };
  

  
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          We'd love to know you better !
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="firstName"
            label="First Name"
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="lastName"
            label="Last Name"
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="company"
            label="Company"
            type="text"
            id="company"
            value={formData.company}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="occupation"
            label="Occupation"
            type="text"
            id="occupation"
            value={formData.occupation}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="country"
            label="Country"
            type="text"
            id="country"
            value={formData.country}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="industry"
            label="Industry"
            type="text"
            id="industry"
            value={formData.industry}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="linkedin"
            label="LinkedIn Profile"
            type="url"
            id="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
          />
          <TermsAndConditionsDialog agreed={agreed} setAgreed={setAgreed}/>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            style={{
              backgroundColor: purple[200], // Darker purple button
              color: '#fff', // White text
              boxShadow: 'none' // Ensure button is flat
            }}
          >
            Update Information
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddMetadataForm;
