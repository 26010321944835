// TextLoader.jsx
import React from 'react';
import './TextLoader.css';

const TextLoader = () => {
  return (
    <div>
    <div style = {{display : 'flex', color: 'gray'}}><p>Kindly check the document's card in the below dashboard</p></div>

    <div class="loaderText">
    <div class="wrapperText">
      <div class="textText">Processing File</div>
      <div class="boxText"></div>
    </div>
    </div>
  </div>
  
  );
};

export default TextLoader;
