import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import { Auth0Provider } from '@auth0/auth0-react';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <Auth0Provider
          domain="dev-5h33aun.us.auth0.com"
          clientId="fvsQ9DT16eWAbmF6IL6ZApvkpdgjtf3L"
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
          // useRefreshTokens={ false }
          cacheLocation="localstorage"
        >
          <App />
        </Auth0Provider>
    </CacheBuster>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

